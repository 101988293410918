<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">CRM </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Agenda</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>


    <div class="mt-2">
        <full-calendar ref="agendaCalendar" :options="calendarOptions"/>
    </div>



    </b-card>
  </section>
</template>

<script>

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction';
import { BBreadcrumb, BBreadcrumbItem, BCard } from "bootstrap-vue";

export default {
  components: {
    FullCalendar,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
  },

  data: () => ({
      calendarOptions: {
          themeSystem: 'bootstrap4',
          plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
          locale: 'it',
          events: [
             { id: 10, title: 'All day event', date: new Date(), allDay: true },
            //  { id: 20, title: 'Timed event', start: moment(new Date()).add(2, 'd') },
          ],
          initialView: 'dayGridMonth',
          views: {
            dayGridMonth: {
              dayHeaderFormat: {weekday: 'long'}
            }
          },
          headerToolbar: {
              start: 'title prev,next today',
              end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
          },
          buttonText: {
              today: 'Oggi',
              month: 'Mese',
              week: "Settimana",
              day: "Giorno",
              list: "Lista",
          }
      }
  })
}

</script>


